import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  style: {
    "margin": "14px",
    "align-items": "center",
    "height": "22px"
  }
};
const _hoisted_4 = {
  class: "floatleft systemfont fontweight6 fontsize16 line22",
  style: {
    "color": "#1C1E41"
  }
};
const _hoisted_5 = {
  class: "kpjestyle"
};
const _hoisted_6 = {
  style: {
    "height": "22px",
    "padding": "14px 12px"
  }
};
const _hoisted_7 = {
  class: "floatleft systemfont fontweight6 fontsize16",
  style: {
    "line-height": "22px",
    "color": "#1C1E41"
  }
};
const _hoisted_8 = {
  class: "titlekpje floatright"
};
const _hoisted_9 = {
  class: "systemfont fontweight6 fontsize16 colorblue aligncenter",
  style: {
    "line-height": "22px"
  }
};
const _hoisted_10 = {
  class: "systemfont fontweight6 fontsize10 colorblue line16 aligncenter"
};
const _hoisted_11 = {
  style: {
    "padding": "14px 12px"
  }
};
const _hoisted_12 = {
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_13 = {
  key: 0
};
const _hoisted_14 = {
  key: 1
};
const _hoisted_15 = {
  key: 2
};
const _hoisted_16 = {
  key: 3
};
const _hoisted_17 = {
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_18 = {
  key: 0,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_19 = {
  key: 1,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_20 = {
  key: 2,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_21 = {
  key: 3,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_22 = {
  key: 4,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_23 = {
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_24 = {
  style: {
    "padding": "14px 12px"
  }
};
const _hoisted_25 = {
  key: 0,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_26 = {
  key: 1,
  style: {
    "padding-top": "8px"
  }
};
const _hoisted_27 = {
  style: {
    "position": "fixed",
    "bottom": "0",
    "left": "0",
    "right": "0",
    "z-index": "999",
    "margin": "12px 14px",
    "align-items": "center"
  }
};
const _hoisted_28 = {
  style: {
    "float": "left",
    "width": "50%"
  }
};
const _hoisted_29 = {
  style: {
    "float": "right",
    "width": "50%"
  }
};
const _hoisted_30 = {
  class: "errtitle systemfont"
};
const _hoisted_31 = {
  class: "errcont systemfont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [$data.showLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Loading)])) : _createCommentVNode("", true), $data.showMain ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    style: _normalizeStyle({
      height: $data.tempheight,
      overflow: 'auto'
    }),
    class: "box"
  }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('RePage.ConIfm')), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('RePage.Fapiaoamount')), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", null, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "systemfont fontweight4 fontsize12 colorblue line18 aligncenter"
  }, "¥", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString($data.kpje.split('.')[0]), 1), _createElementVNode("span", _hoisted_10, _toDisplayString('.' + $data.kpje.split('.')[1]), 1)])])]), _cache[6] || (_cache[6] = _createElementVNode("hr", {
    class: "tophr"
  }, null, -1)), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss1)
  }, _toDisplayString(_ctx.$t('AppPage.Fapiaoinformation')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " ", 2)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss),
    style: {
      "width": "150px"
    }
  }, _toDisplayString(_ctx.$t('RePage.Fapiaotype')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, [this.$store.state.invoiceRequestInvoiceType == 'EC' ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('AppPage.Valueaddec')), 1)) : _createCommentVNode("", true), this.$store.state.invoiceRequestInvoiceType == 'BEC' ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('AppPage.Valueaddbec')), 1)) : _createCommentVNode("", true), this.$store.state.invoiceRequestInvoiceType == 'BES' ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('AppPage.Valueaddbes')), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_16, " "))], 2)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('RePage.Fapiaotitle')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString(_ctx.$store.state.kpinform.buyerName), 3)]), this.$store.state.kpinform.buyerType == 0 && this.$store.state.kpinform.taxNo ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('RePage.TaxRegisNumber')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString(_ctx.$store.state.kpinform.taxNo), 3)])) : _createCommentVNode("", true), this.$store.state.kpinform.buyerType == 0 && this.$store.state.kpinform.compAddress ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('RePage.Company')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString(_ctx.$store.state.kpinform.compAddress), 3)])) : _createCommentVNode("", true), this.$store.state.kpinform.buyerType == 0 && this.$store.state.kpinform.bank ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('RePage.Bankdeposit')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString(_ctx.$store.state.kpinform.bank), 3)])) : _createCommentVNode("", true), this.$store.state.kpinform.buyerType == 0 && this.phone ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('RePage.Phone')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString($data.phone.substring(0, 3)) + " " + _toDisplayString($data.phone.substring(3, 7)) + " " + _toDisplayString($data.phone.substring(7, 11)), 3)])) : _createCommentVNode("", true), this.$store.state.kpinform.buyerType == 0 && this.$store.state.kpinform.BankAddress ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('RePage.Bankaccount')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString(_ctx.$store.state.kpinform.BankAddress), 3)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss)
  }, _toDisplayString(_ctx.$t('AppPage.Remarks')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " " + _toDisplayString(_ctx.$store.state.kpinform.memo), 3)])]), _cache[7] || (_cache[7] = _createElementVNode("hr", {
    class: "tophr"
  }, null, -1)), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", null, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss1)
  }, _toDisplayString(_ctx.$t('AppPage.information')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, " ", 2)]), this.$store.state.receive.sendType == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_25, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss2)
  }, _toDisplayString(_ctx.$t('RePage.Mobile')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, _toDisplayString(_ctx.$store.state.receive.sendPhone), 3)])) : (_openBlock(), _createElementBlock("div", _hoisted_26, [_createElementVNode("div", {
    class: _normalizeClass($data.titlecss2)
  }, _toDisplayString(_ctx.$t('RePage.Email')), 3), _createElementVNode("div", {
    class: _normalizeClass($data.valuecss)
  }, _toDisplayString(_ctx.$store.state.receive.sendEmail), 3)]))])])], 4), _createElementVNode("div", _hoisted_27, [_createElementVNode("div", _hoisted_28, [_createElementVNode("div", {
    class: _normalizeClass($data.closecancel),
    style: {
      "margin": "0 5px 0 0"
    },
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onClickBack && $options.onClickBack(...args))
  }, _toDisplayString(_ctx.$t('RePage.Return')), 3)]), _createElementVNode("div", _hoisted_29, [_createElementVNode("div", {
    class: _normalizeClass($data.closeyes),
    style: {
      "margin": "0 0 0 5px"
    },
    onClick: _cache[1] || (_cache[1] = (...args) => $options.onClickSubmit && $options.onClickSubmit(...args))
  }, _toDisplayString(_ctx.$t('RePage.Confirm')), 3)])]), _createVNode(_component_van_popup, {
    show: $data.showFail,
    "onUpdate:show": _cache[4] || (_cache[4] = $event => $data.showFail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('ManPage.errtitle')), 1), _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('ErrMsg.current')), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyesy),
      onClick: _cache[2] || (_cache[2] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancely),
      onClick: _cache[3] || (_cache[3] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true)]);
}